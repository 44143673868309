import { lazy, Suspense } from 'react';
import {
    Navigate,
    createRoutesFromElements,
    createBrowserRouter,
    Route,
    RouterProvider,
    ScrollRestoration,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import 'web/taswiya/App.scss';
import taswiyaFavicon from 'dsl_web/img/logos/taswiya.png';

// taswiya
const Layout = lazy(() => import('web/taswiya/marketing/components/Layout.tsx'));
const Home = lazy(() => import('web/taswiya/marketing/Home.tsx'));
const Programs = lazy(() => import('web/taswiya/marketing/Programs.tsx'));
const About = lazy(() => import('web/taswiya/marketing/About.tsx'));
const Gallery = lazy(() => import('web/taswiya/marketing/Gallery.tsx'));
const Policy = lazy(() => import('web/taswiya/marketing/Policy.tsx'));
const OurTeam = lazy(() => import('web/taswiya/marketing/OurTeam.tsx'));
const ContactUs = lazy(() => import('web/taswiya/marketing/ContactUs.tsx'));
const PageNotFound = lazy(() => import('web/taswiya/marketing/404.tsx'));

const appRoutes = createRoutesFromElements(
    <>
        <Route
            path="/"
            element={
                <Suspense>
                    <Layout />
                </Suspense>
            }
        >
            <Route
                path=""
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - Home </title>
                        </Helmet>
                        <Suspense>
                            <Home />
                        </Suspense>
                    </>
                }
            />
            <Route path="home" element={<Navigate to="/" />} />
            <Route
                path="programs"
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - Programs </title>
                        </Helmet>
                        <Suspense>
                            <Programs />
                        </Suspense>
                    </>
                }
            />
            <Route
                path="about"
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - About us </title>
                        </Helmet>
                        <Suspense>
                            <About />
                        </Suspense>
                    </>
                }
            />
            <Route
                path="gallery"
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - Our Gallery </title>
                        </Helmet>
                        <Suspense>
                            <Gallery />
                        </Suspense>
                    </>
                }
            />
            <Route
                path="policy"
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - Policy</title>
                        </Helmet>
                        <Suspense>
                            <Policy />
                        </Suspense>
                    </>
                }
            />
            <Route
                path="team"
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - Our Team</title>
                        </Helmet>
                        <Suspense>
                            <OurTeam />
                        </Suspense>
                    </>
                }
            />
            <Route
                path="contact"
                element={
                    <>
                        <Helmet>
                            <title> Taswiya - Contact Us</title>
                        </Helmet>
                        <Suspense>
                            <ContactUs />
                        </Suspense>
                    </>
                }
            />
            <Route
                path="*"
                element={
                    <>
                        <Helmet>
                            <title> Page not found - Taswiya</title>
                        </Helmet>
                        <Suspense>
                            <PageNotFound />
                        </Suspense>
                    </>
                }
            />
        </Route>
    </>
);

const appRouter = createBrowserRouter(appRoutes);

const App = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <link id="favicon" rel="icon" href={taswiyaFavicon} type="image/x-icon" />
            </Helmet>

            <RouterProvider router={appRouter} future={{ v7_startTransition: true }}>
                <ScrollRestoration />
            </RouterProvider>

            <ToastContainer position="bottom-right" theme="dark" transition={Slide} newestOnTop={true} />
        </HelmetProvider>
    );
};

export default App;
