import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import ErrorBoundary from 'dsl_web/components/ErrorBoundary.tsx';

import App from 'web/taswiya/App.tsx';
import httpCodes from 'web/taswiya/httpCodes.ts';
import fileHttpCodes from 'web/taswiya/fileHttpCodes.ts';
import store from 'web/taswiya/redux/store.ts';

import env from 'web/taswiya/env.ts';

global.env = env;
global.httpCodes = httpCodes;
global.fileHttpCodes = fileHttpCodes;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>
);
