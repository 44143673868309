const webEnv = {
    apiBaseUrl: process.env.TASWIYA_API_BASE_URL!,
    webBaseUrl: process.env.TASWIYA_WEB_BASE_URL!,
    gcpProjectNumber: process.env.GCP_PROJECT_NUMBER!,
    stripeKey: process.env.STRIPE_PUBLIC_KEY!,
    stripeCusDashboardUrl: process.env.STRIPE_CUS_DASHBOARD_URL!,
    googleDriveClientId: process.env.GOOGLE_DRIVE_CLIENT_ID!,
    googleDriveApiKey: process.env.GOOGLE_DRIVE_API_KEY!,
    dropboxAppKey: process.env.DROPBOX_APP_KEY!,
    oneDriveClientId: process.env.ONEDRIVE_CLIENT_ID!,
};

declare global {
    var env: typeof webEnv;
}

globalThis.env = webEnv;

export default webEnv;
