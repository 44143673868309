import { combineReducers } from 'redux';

import titleReducer from 'web/taswiya/redux/reducers/pageTitle.ts';
import cookieReducer from 'web/taswiya/redux/reducers/cookie.ts';

const rootReducer = combineReducers({
    pageTitle: titleReducer,
    cookie: cookieReducer,
});

export default rootReducer;
