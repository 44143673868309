import { createStore } from 'redux';

import rootReducer from 'web/taswiya/redux/reducers/index.ts';

// get any persisted state from before.
const localStorageState = localStorage.getItem('reduxState');
const persistedState = localStorageState ? JSON.parse(localStorageState) : {};

const store = createStore(
    rootReducer,
    persistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// always persist state on any change.
store.subscribe(() => {
    const state = store.getState();
    localStorage.setItem('reduxState', JSON.stringify(state));
});

export default store;
