const initialTitle = 'Taswiya';

const titleReducer = (state = initialTitle, action) => {
    switch (action.type) {
        case 'PAGETITLE':
            return action.payload;
        default:
            return state;
    }
};

export default titleReducer;
